import React, { useEffect, useState } from 'react';
import { breakpoints as breakpoints } from '@src/breakpoints';
import useStore from '@store';
import * as styles from './styles.css.js';

const ResponsiveImage = ({image}) => {
  const [viewport] = useStore(state => [state.viewport]);
  const [aspectRatio, setAspectRatio] = useState(null);

  useEffect(() => {
    let _aspectRatio = null;

    if (viewport === 'mobile') {
      _aspectRatio = `${image.width_mobile} / ${image.height_mobile}`;
    }
    else if (viewport === 'desktop') {
      _aspectRatio = `${image.width_desktop} / ${image.height_desktop}`;
    }

    setAspectRatio(_aspectRatio);
  }, [viewport]);

  return (
    <div className={styles.image}>

      <picture>
        <source
          srcSet={image.src_desktop}
          media={`(min-width: ${breakpoints.md}px)`}
        />
        <source
          srcSet={image.src_mobile}
          media="(min-width: 0px)"
        />
        <img
          loading='lazy'
          src={image.src_mobile}
          alt={image.alt}
          style={{'aspectRatio': aspectRatio !== null ? aspectRatio : null}}
        />
      </picture>
    </div>
  );
}

export default ResponsiveImage;
