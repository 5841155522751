import "src/styles/theme.css.js.vanilla.css!=!../../../node_modules/@vanilla-extract/webpack-plugin/virtualFileLoader/dist/vanilla-extract-webpack-plugin-virtualFileLoader.cjs.js?{\"fileName\":\"src/styles/theme.css.js.vanilla.css\",\"source\":\"#H4sIAAAAAAAAE42T0U7DMAxF3/kKSwgJpG5KUidtso9BbRmDQenWdRMS4t9xKlaw24dIVR7uPbFvnDT0XTfA1w3AavWom1N/xFYFMNZm07Jhrg6gsvHjuqFdJfHk6IJbOVnoyMNYEJG7GF0dDdpq8pK7NroYXRNdUdkFuC3RN7UIU5Cee3RWc70MoD2FyCmpVsL0AUqfgcsz8OLQFaUwlFE7iuJFszpAv6ure3aKB840v8xfAyommKeEOtslhu5i7Y0gn6/kdCkRkz13SdTLIuWswF6Tiu2vlKJZ6shZPR/GWxL1vtixkMHaaWr/XuE4NQF+LNaTl9AlUQf6UaA6Dx2Xj/QElbrjYk8iKnX45PIpAPbblosDiWs7k88B3Ey80JMexe8fsHWdo+kDAAA=\"}!../../../node_modules/@vanilla-extract/webpack-plugin/extracted.js"
import "src/styles/fonts.css.js.vanilla.css!=!../../../node_modules/@vanilla-extract/webpack-plugin/virtualFileLoader/dist/vanilla-extract-webpack-plugin-virtualFileLoader.cjs.js?{\"fileName\":\"src/styles/fonts.css.js.vanilla.css\",\"source\":\"#H4sIAAAAAAAAE72UwWrCQBCG73mKvUUPqR5qhXgRC4Ue2kOFXssaZ+3AJls2EyQU370xoqAimZphjzvL/Pvtd/jnxhWUGJ2B+o2Uak8l1RZSVTifazs7TreAm29K1eN4vJ+VPktV5e0gHu2vy9G7I7cEjyb5gE1ltX8gMvGwWW5iaBCTr4DqH4iHp0ijc7R1qk6rs2gXzW8TIWmL2R1Er+2iPNANRZNOoDdYY5UHM8QFCuzpqRNrCTkunF0HM8VHCuxq2gkW1BMPR9LR1VPPeoUFv2xYrXZG0z7AJ+F+llVmfUC45cIqsf4gMl4EcPhlwqotCRQZNwJAEl6mMhgyTnrBLJAIfPKpPeqVhZdm+LVtYu81dAQ45P6D4OCiB8iFnSuQP0LTQTYACgAA\"}!../../../node_modules/@vanilla-extract/webpack-plugin/extracted.js"
import "src/components/project-item/styles.css.js.vanilla.css!=!../../../node_modules/@vanilla-extract/webpack-plugin/virtualFileLoader/dist/vanilla-extract-webpack-plugin-virtualFileLoader.cjs.js?{\"fileName\":\"src/components/project-item/styles.css.js.vanilla.css\",\"source\":\"#H4sIAAAAAAAAE7VXS3PbNhC++1fg0lqaiCxJiRRFz3Ta5J5Dk3sGIiESMUgwICTZ9fi/FwDBF0DJiqc5eGAC+8B+++0u5H7bFazYBB54uQMgw01N4HMC9oSmjw9ip4Qsx5XDaZ2AE2QLx/nmpw37sSmbpTz/PTlg1nAnLTDJlI2pjieFXu9e71ztKFBCNcwyXOWtTEpgWS88NwoZKlcgcIPwdF4B35XfwsugvFbKe8oyxJw95ZyWCfDrJ9BQgjPA8j1cTG8ZLFfAEwbVZTuvnWbneNs6Xl/wurnulTNYNTVkqOJzTryxqVCZOtCKOwdYYiKgvv+IOUfsfgXuv+ISNeAzOoN/aAkrsdUghg8PncoZ4bzgCYg8BSvBFXIKvee7fi/X4H9RF53vxqPoAnfbRgdASgllZlKrSeDRLCmG8+30vKIVGh/H149D8CeA4k9gVylJzRvWBjTBbdeyhjaYY1olgCECOT6hh7EDXClEeu6eccaLBKy9+kl+dkh137SGKeZCz3ND+a24GLZnKqcHykT+mhQSJEgShxNs4EwmP8E9llm7/5thSGT6hBVnPoebNoejfPnuWuRmJrFBeDFfdGkWqSS7MjNcdX89D6k69hIApS1fRCzXQK/rBFC5bgSh5BoKnOUaafltAhTtY72/0/K+MFhN3bSQXYxjEM2mF84ZzqRJuToclWKXI0fYOZZVI7lQI8gXfiCK98AUIIyenRzWia7mcVkqkHwDIaQcKvutWSHyh7/unQp7YqeF+snRvIpiRaSxlXoFho8jEYmcYclnyukXyYlbSl5TQwcxxyCDLNvLZCFL47bgg3VjY0eKiN0Xg2Q2fkOwBItx0PBnghz+XKOOb70Bgg5mdXfqM54uyV1y1vw4ik5suQusC18h43fFIaPfB6LfZ+J/ZDT8weZhylqzG2kC9hUK9NwxC3ig7B6mjzmjx0qxUt5zZsBBNeB245Z+LjBHfRfTHdM0JpT8BiDYIIceVRxwGohp7Lb5N8CR/4KWfUKMY9GPHUhwLmwKwPrObXf1thQOmBATE6OzT+qiuKUXBFLjrxJlGAozDKEKwCoDi1JkUcewke1hqYy5k0fWtUcVX7bvpUEn0jpG+54KbU2hHtCx1E5LjQfdLCA76xYXW5sxwbpGNdYt+qj71ilaV+d+FujXN9HdRvHPo3u04grtu23D3/6fHMwJxTclynoZWW+jvke8I7++hUL3cO/HZqCtm2Oze1u8jxyzyvn40n576bfT73vB5ufzf7Iin0C27rxfQcazg3+Z43F8QxV0BDGUd7oIrLfHCJZFQUUvTIBapjBEACZqezXZgqlsvqsRXS2x7YxYbIvFvZgOaXaIPlpYp7ap9J2mkG0KmaYuz+8562+6HqZj63l64X683Di1g9HUvv2npbqHdm8yuJ97fmuzHXvz0bxZXZFnza5JpQR9nf4HMwVzbUAQAAA=\"}!../../../node_modules/@vanilla-extract/webpack-plugin/extracted.js"
export var association = '_9hrh42c';
export var description = '_9hrh42e';
export var details = '_9hrh421';
export var detailsBottom = '_9hrh42d';
export var detailsTop = {bordered:'_9hrh423 _9hrh422',unbordered:'_9hrh424 _9hrh422'};
export var item = '_9hrh420';
export var projectDescriptionLinkSvg = '_9hrh42g';
export var projectLink = '_9hrh42f';
export var projectTitleLinkSvg = '_9hrh429';
export var recognition = '_9hrh42h';
export var sub = '_9hrh42a';
export var subRole = '_9hrh42b';
export var title_bp_md = '_9hrh428 _9hrh425';
export var title_bp_sm = '_9hrh427 _9hrh425';
export var title_bp_xs = '_9hrh426 _9hrh425';