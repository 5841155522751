import { create } from 'zustand';

const initialState = {
  viewport: null,
};

const useStore = create((set, get) => ({
  ...initialState,
  actions: {
    setViewport(viewport) {
      set({ viewport });
    },
  },
}));

export default useStore;
