
const breakpoints = {
  xs: 0,
  sm: 480,
  md: 768,
  lg: 1024,
  xl: 1280,
  xx: 1600,
};

const queries = {
  up: {},
  down: {},
  only: {},
};

Object.entries(breakpoints).forEach(([key, val], index) => {

  // default breakpoint behavior (up)
  queries.up[key] = `screen and (min-width: ${val}px)`;

  // build the 'down' breakpoints
  // if there's one below, use its value as max width
  if (Object.entries(breakpoints)[index-1]) {
    // const nextKey = Object.keys(breakpoints)[index-1];
    // const nextVal = Object.values(breakpoints)[index-1];
     // the -1 makes sure it doesn't match default breakpoint behavior (matching to >=)
    queries.down[key] = `screen and (max-width: ${val-1}px)`;
  }
  // otherwise, smallest should never be satisfied
  else {
     // the -1 makes sure it doesn't match default breakpoint behavior (matching to >=)
    queries.down[key] = `screen and (max-width: ${val-1}px)`;
  }

  // build the 'only' exact match breakpoints
  // if there's one above, use its value as max width
  if (Object.entries(breakpoints)[index+1]) {
    // const nextKey = Object.keys(breakpoints)[index+1];
    const nextVal = Object.values(breakpoints)[index+1];
    queries.only[key] = `screen and (min-width: ${val}px) and (max-width: ${nextVal}px)`;
  }
  // otherwise, unrestricted max width
  else {
    queries.only[key] = `screen and (min-width: ${val}px)`;
  }
});

export {
  breakpoints,
  queries,
};