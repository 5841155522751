import React from 'react';
import * as styles from './styles.css.js';

const ResponsiveVideo = ({video}) => {
  return (
    <div className={styles.video}>
      <video
        className={styles.video_mobile}
        autoPlay
        muted
        loop
        preload="none"
        playsInline
        type="video/mp4"
        src={video.src_mobile}
        poster=""
        style={{'aspectRatio': `${video.width_mobile} / ${video.height_mobile}`}}
      />
      <video
        className={styles.video_desktop}
        autoPlay
        muted
        loop
        preload="none"
        playsInline
        type="video/mp4"
        src={video.src_desktop}
        poster=""
        style={{'aspectRatio': `${video.width_desktop} / ${video.height_desktop}`}}
      />
    </div>
  );
}

export default ResponsiveVideo;
