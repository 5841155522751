import React, { useState, useRef, useEffect } from 'react';
import { InView } from 'react-intersection-observer';
import ProjectItem from '@components/project-item';
import * as styles from './styles.css.js';

const ENABLE_PROJECT_FILTERS = true;

const ProjectList = ({
  projects,
  tags,
}) => {

  const [currentFilter, setCurrentFilter] = useState(ENABLE_PROJECT_FILTERS ? 'featured' : 'all');
  const [filteredProjects, setFilteredProjects] = useState([]);
  const [filtersPinned, setFiltersPinned] = useState([]);
  const [filterClickedCount, setFilterClickedCount] = useState(0);
  const projectsRef = useRef();
  const projectsListRef = useRef();
  let fadeInResetTimeout = null;

  // console.log(projects);
  // console.log(tags);

  const onClickFilterLabel = (id) => {
    visuallyRefreshProjectsList();
    setCurrentFilter(id);
    setFilterClickedCount(filterClickedCount + 1);
  }

  const visuallyRefreshProjectsList = () => {
    projectsListRef.current.classList.remove('fadeIn');
    projectsListRef.current.classList.add('fadeIn');
    
    clearFadeInResetTimeout();

    fadeInResetTimeout = window.setTimeout(() => {
      if (projectsListRef && projectsListRef.current) {
        projectsListRef.current.classList.remove('fadeIn');
      }
    }, 650);
  }

  const clearFadeInResetTimeout = () => {
    if (fadeInResetTimeout !== null) {
      window.clearInterval(fadeInResetTimeout);
    }
  }

  useEffect(() => {
    return () => {
      clearFadeInResetTimeout();
    }
  }, []);

  useEffect(() => {
    let filtered = [];

    if (currentFilter === 'all') {
      filtered = projects;
    }
    else {
      filtered = projects.filter((project) => {
        return project.tags.some(tag => tag['id'] === currentFilter)
      });
    }

    setFilteredProjects(filtered);

  }, [currentFilter]);

  useEffect(() => {
    if (filterClickedCount > 0) {
      projectsRef.current.scrollIntoView({
        behavior: 'smooth',
        block: 'start',
      });
    }
  }, [filterClickedCount]);

  // useEffect(() => {
  // }, [filteredProjects]);

  useEffect(() => {
    setCurrentFilter(currentFilter);
  }, []);

  return (
    <div className={styles.projects} ref={projectsRef}>

      { ENABLE_PROJECT_FILTERS ?
        <div className={styles.filtersWrapper[filtersPinned ? 'pinned' : 'unpinned']}>

          <InView
            as='div'
            threshold={1}
            className={styles.detection}
            onChange={(inView, entry) => {
              setFiltersPinned(!inView && entry.boundingClientRect.top < 0);
            }}
          />

            <span
              className={styles.filter}
              onClick={() => {
                onClickFilterLabel('all');
              }}
            >
              <span className={styles.filterLabel[currentFilter === 'all' ? 'active' : 'inactive']}>All work</span>
            </span>

            { tags.length > 0 ? tags.map((item, idx) => {
              return (
                <span
                  className={styles.filter}
                  onClick={() => {
                    onClickFilterLabel(item.id);
                  }}
                  key={idx}
                >
                  <span className={styles.filterLabel[currentFilter === item.id ? 'active' : 'inactive']}>
                    {item.label}
                  </span>
                </span>
              )
            })
            : null }

        </div>
      : null }

      <div className={styles.projectsList} ref={projectsListRef}>

        { filteredProjects.length > 0 ? filteredProjects.map((item, idx) => {
          return (
            <ProjectItem
              item={item}
              key={`${item.id}_${idx}`}
            />
          )
        })
        :
          <div>
            <p>No work to show.</p>
            <p>:(</p>
          </div>
        }
      </div>

      { currentFilter !== 'all' ?
        <div className={styles.seeAllLink}>
          <span onClick={() => { onClickFilterLabel('all'); }}>See all work</span>
        </div>
      : null }

    </div>
  );
}

export default ProjectList;
