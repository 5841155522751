const easing = {

  easeIn: ( power, t ) => { return Math.pow(t, power) },
  easeOut: ( power, t ) => { return function( t ) { return 1 - Math.abs(Math.pow(t-1, power)) } },

  linear: ( t ) => {
    return t;
  },

  easeInSin: ( t ) => {
    return 1 + Math.sin(Math.PI / 2 * t - Math.PI / 2);
  },
  easeOutSin: ( t ) => {
    return Math.sin(Math.PI / 2 * t);
  },
  easeInOutSin: ( t ) => {
    return (1 + Math.sin(Math.PI * t - Math.PI / 2)) / 2;
  },

  easeInQuad: ( t ) => {
    return t*t;
  },
  easeOutQuad: ( t ) => {
    return t*(2-t);
  },
  easeInOutQuad: ( t ) => {
    return t<.5 ? 2*t*t : -1+(4-2*t)*t;
  },

  easeInCubic: ( t ) => {
    return t*t*t;
  },
  easeOutCubic: ( t ) => {
    return (--t)*t*t+1;
  },
  easeInOutCubic: ( t ) => {
    return t<.5 ? 4*t*t*t : (t-1)*(2*t-2)*(2*t-2)+1;
  },

  easeInQuart: ( t ) => {
    return t*t*t*t;
  },
  easeOutQuart: ( t ) => {
    return 1-(--t)*t*t*t;
  },
  easeInOutQuart: (t) => {
    return t<.5 ? 8*t*t*t*t : 1-8*(--t)*t*t*t;
  },

  easeInQuint: ( t ) => {
    return t*t*t*t*t;
  },
  easeOutQuint: ( t ) => {
    return 1+(--t)*t*t*t*t;
  },
  easeInOutQuint: ( t ) => {
    return t<.5 ? 16*t*t*t*t*t : 1+16*(--t)*t*t*t*t;
  },
};

easing.easeInOut = ( power, t ) => { return function( t ) { return t<.5 ? easing.easeIn(power)(t*2)/2 : easing.easeOut(power)(t*2 - 1)/2+0.5 } };

export default easing;
