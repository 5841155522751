import React from 'react';
import * as styles from './styles.css.js';

const RecognitionItem = ({item}) => {
  const getRecognitionFragment = (item) => {
    switch (item.type) {
      case 'award':
        if (item.link_url !== null) {
          return (
            <span>
              <a href={ item.link_url } target="_blank"><strong>{ item.award_name } - { item.place }</strong> ({item.year})<br />
                { item.category }
              </a>
            </span>
          );
        }
        else {
          return (
            <span>
              <strong>{ item.award_name } - { item.place }</strong> ({item.year})<br />
              { item.category }
            </span>
          );
        }
        break;
      case 'exhibit':
        return (
          <span>
            <a href={ item.link_url } target="_blank"><strong>{ item.exhibit_title }</strong><br />
              { item.exhibit_location } ({item.year})
            </a>
          </span>
        );
        break;
      default:
        throw new Error(`Must be of type 'award' or 'exhibit'.`);
    }
  }

  return (
    <li className={styles.recognitionItem}>
      {( getRecognitionFragment(item) )}
    </li>
  );
}

export default RecognitionItem;
