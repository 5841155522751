import React, { useState, useRef, useEffect } from 'react';
import { cover, contain } from '@utilities/intrinsic-scale'
import * as styles from "./styles.css.js";

let frameInterval = null;

const BYTES_PER_PIXEL = 4;
const PIXEL_SHIFT = 0.25; // attempts to shift halftone pixels to more evenly fill the canvas
const PI2 = 2 * Math.PI;

const CYAN_OFFSET_X = 0.25;
const CYAN_OFFSET_Y = 0.42;
const MAGENTA_OFFSET_X = -0.25;
const MAGENTA_OFFSET_Y = 0.42;
const YELLOW_OFFSET_X = 0;
const YELLOW_OFFSET_Y = 0;

const CMY_DOT_OPACITY = 1;
const CYAN_VALUE = '0, 240, 240';
const MAGENTA_VALUE = '240, 0, 240';
const YELLOW_VALUE = '240, 240, 0';
const CYAN_DOT_MULTIPLIER = 1; // size of dot
const MAGENTA_DOT_MULTIPLIER = 1; // size of dot
const YELLOW_DOT_MULTIPLIER = 1; // size of dot

const RED_OFFSET_X = 0.25;
const RED_OFFSET_Y = 0.42;
const GREEN_OFFSET_X = -0.25;
const GREEN_OFFSET_Y = 0.42;
const BLUE_OFFSET_X = 0;
const BLUE_OFFSET_Y = 0;

const RGB_DOT_OPACITY = 1;
const RED_VALUE = '240, 0, 0';
const GREEN_VALUE = '0, 240, 0';
const BLUE_VALUE = '0, 0, 240';
const RED_DOT_MULTIPLIER = 1; // size of dot
const GREEN_DOT_MULTIPLIER = 1; // size of dot
const BLUE_DOT_MULTIPLIER = 1; // size of dot

const Halftone = ({
  type,
  source,
  targetWidth,
  targetHeight,
  dotSize,
  bwDotColor = null,
  mode,
  backgroundColor = null,
  fps = 30,
}) => {
  const sourceCanvasRef = useRef();
  const sourceVideoRef = useRef();
  const targetCanvasRef = useRef();

  const sourceCtxRef = useRef(null);
  const targetCtxRef = useRef(null);

  const [videoFrameRate, setVideoFrameRate] = useState(null);
  const [sourceImgLoaded, setSourceImgLoaded] = useState(null);

  const [sourceCalculatedWidth, setSourceCalculatedWidth] = useState(1);
  const [sourceCalculatedHeight, setSourceCalculatedHeight] = useState(1);

  const VIDEO_FRAME_RATE = fps;

  const BW_DOT_COLOR = (() => {
    if (bwDotColor !== null) return bwDotColor;
    return 'rgba(255, 255, 255, 1)';
  })(bwDotColor);

  const CANVAS_BG_COLOR = (() => {
    if (backgroundColor !== null) return backgroundColor;
    if (mode === 'bw') return backgroundColor;
    if (mode === 'cmy') return 'white';
    if (mode === 'rgb') return 'black';
  })(mode, backgroundColor);

  const MIX_MODE = (() => {
    if (mode === 'bw') return 'source-over';
    if (mode === 'cmy') return 'multiply';
    if (mode === 'rgb') return 'screen';
  })(mode);

  useEffect(() => {
    const sourceCtx = getSourceCanvasContext();
    const targetCtx = getTargetCanvasContext();

    sourceCtx.imageSmoothingEnabled = true; // get free higher-quality antialiasing when source is scaled
    // sourceCtx.clearRect(0, 0, sourceCanvasRef.current.width, sourceCanvasRef.current.height);

    targetCtx.globalCompositeOperation = MIX_MODE;
    // targetCtx.imageSmoothingEnabled = false; // better performance in theory
    // targetCtx.clearRect(0, 0, targetCanvasRef.current.width, targetCanvasRef.current.height);

    if (targetWidth && targetHeight) {
      if (type === 'image') {
        const img = new Image();
        img.crossOrigin = 'anonymous';
        img.src = source;

        img.addEventListener('load', () => {
          go(img);
        });
      }
      else if (type === 'video') {
        sourceVideoRef.current.addEventListener('loadedmetadata', goVideo);
      }
    }

    // @TODO: get this working to clean up properly
    return () => {
      window.clearInterval(frameInterval);
      frameInterval = null;

      sourceVideoRef.current.removeEventListener('loadedmetadata', goVideo);

      const sourceCtx = getSourceCanvasContext();
      const targetCtx = getTargetCanvasContext();

      sourceCtx.clearRect(0, 0, sourceCtx.width, sourceCtx.height);
      targetCtx.clearRect(0, 0, targetCtx.width, targetCtx.height);
    };

  }, [source, dotSize, mode, bwDotColor, backgroundColor, targetWidth, targetHeight, type]);

  const getSourceCanvasContext = () => {
    return sourceCtxRef.current !== null ? sourceCtxRef.current : sourceCanvasRef.current.getContext('2d', {willReadFrequently: true});
  }

  const getTargetCanvasContext = () => {
    return targetCtxRef.current !== null ? targetCtxRef.current : targetCanvasRef.current.getContext('2d', {willReadFrequently: true});
  }

  const goVideo = () => {
    const sourceCanvasWidth = getSourceWidth();
    const sourceCanvasHeight = getSourceHeight();

    const {
      offsetX, 
      offsetY, 
      width, 
      height,
    } = cover(sourceCanvasWidth, sourceCanvasHeight, sourceVideoRef.current.videoWidth, sourceVideoRef.current.videoHeight)

    const sourceCtx = getSourceCanvasContext();
    const targetCtx = getTargetCanvasContext();

    // @TODO: use animation frame rather than setInterval
    window.clearInterval(frameInterval);
    frameInterval = null;
    frameInterval = window.setInterval(() => {
      targetCtx.clearRect(0, 0, targetCanvasRef.current.width, targetCanvasRef.current.height);
      sourceCtx.drawImage(sourceVideoRef.current, offsetX, offsetY, width, height);

      const imgData = sourceCtx.getImageData(0, 0, sourceCanvasWidth, sourceCanvasHeight);

      createHalftone({
        imgData: imgData,
        targetCtx: targetCtx,
      });

    }, Math.floor((1 / fps) * 1000));
  }

  const go = (img) => {
    const sourceCanvasWidth = getSourceWidth();
    const sourceCanvasHeight = getSourceHeight();

    const sourceCtx = getSourceCanvasContext();
    const targetCtx = getTargetCanvasContext();

    // const sourceCtx = sourceCanvasRef.current.getContext('2d', {willReadFrequently: true});
    sourceCtx.imageSmoothingEnabled = true; // get free higher-quality antialiasing when source is scaled
    sourceCtx.clearRect(0, 0, sourceCanvasRef.current.width, sourceCanvasRef.current.height);

    // const targetCtx = targetCanvasRef.current.getContext('2d', {willReadFrequently: true});
    targetCtx.globalCompositeOperation = MIX_MODE;
    targetCtx.imageSmoothingEnabled = false; // better performance in theory
    targetCtx.clearRect(0, 0, targetCanvasRef.current.width, targetCanvasRef.current.height);

    // get natural dimensions of image so we can crop as necessary
    const srcImgNaturalWidth = img.naturalWidth;
    const srcImgNaturalHeight = img.naturalHeight;

    const {
      offsetX, 
      offsetY, 
      width, 
      height,
    } = cover(sourceCanvasWidth, sourceCanvasHeight, srcImgNaturalWidth, srcImgNaturalHeight)

    sourceCtx.drawImage(img, offsetX, offsetY, width, height); // use this for the dev palette (anchored top-left)

    const imgData = sourceCtx.getImageData(0, 0, sourceCanvasWidth, sourceCanvasHeight);

    createHalftone({
      imgData: imgData,
      targetCtx: targetCtx,
    });
  }

  const createHalftone = ({
    imgData,
    targetCtx,
  }) => {

    const PIXEL_RADIUS = dotSize / 2;

    const sourceCanvasWidth = getSourceWidth();
    const sourceCanvasHeight = getSourceHeight();

    // @TODO: not exactly sure how or why this works, but it does
    const xRatio = targetWidth / sourceCanvasWidth;
    const yRatio = targetHeight / sourceCanvasHeight;
    const primeRatio = xRatio > yRatio ? xRatio : yRatio;
    // const numRows = imgData.data.length / (BYTES_PER_PIXEL * SOURCE_WIDTH);

    const t0 = performance.now();

    for (let cursor = 0; cursor < imgData.data.length; cursor += (BYTES_PER_PIXEL)) {
      const horizontalShift = 0; // shifts columns horizontally
      const column = Math.floor(((cursor / BYTES_PER_PIXEL) + horizontalShift) % sourceCanvasWidth);
      const row = Math.floor(cursor / (BYTES_PER_PIXEL * sourceCanvasWidth));

      // offset rows like a checkerboard
      if ((row % 2 === 0 && column % 2 === 0) || (row % 2 === 1 && column % 2 === 1)) {
        const r = imgData.data[cursor];
        const g = imgData.data[cursor+1];
        const b = imgData.data[cursor+2];
        const a = imgData.data[cursor+3];

        const c = 1 - (r / 255);
        const m = 1 - (g / 255);
        const y = 1 - (b / 255);

        if (mode === 'bw') {
          const bwAlpha = toGreyscale({r, g, b});

          // half tone, single dot
          targetCtx.beginPath();
          targetCtx.arc(
            (column * primeRatio) + (primeRatio * PIXEL_SHIFT),
            (row * primeRatio) + (primeRatio * PIXEL_SHIFT),
            (bwAlpha/256) * PIXEL_RADIUS, // radius
            0,
            PI2,
          );
          targetCtx.fillStyle = BW_DOT_COLOR;
          targetCtx.fill();
          targetCtx.closePath();
        }

        else if (mode === 'cmy') {

          // cyan
          targetCtx.beginPath();
          targetCtx.arc(
            (column * primeRatio) + (primeRatio * (PIXEL_SHIFT + CYAN_OFFSET_X)),
            (row * primeRatio) + (primeRatio * (PIXEL_SHIFT + CYAN_OFFSET_Y)),
            ((c * CYAN_DOT_MULTIPLIER) * PIXEL_RADIUS), // radius, scaled to value
            0,
            PI2,
          );
          targetCtx.fillStyle = `rgba(${CYAN_VALUE}, ${CMY_DOT_OPACITY})`;
          targetCtx.fill();
          targetCtx.closePath();

          // magenta
          targetCtx.beginPath();
          targetCtx.arc(
            (column * primeRatio) + (primeRatio * (PIXEL_SHIFT + MAGENTA_OFFSET_X)),
            (row * primeRatio) + (primeRatio * (PIXEL_SHIFT + MAGENTA_OFFSET_Y)),
            ((m * MAGENTA_DOT_MULTIPLIER) * PIXEL_RADIUS), // radius, scaled to value
            0,
            PI2,
          );
          targetCtx.fillStyle = `rgba(${MAGENTA_VALUE}, ${CMY_DOT_OPACITY})`;
          targetCtx.fill();
          targetCtx.closePath();

          // yellow
          targetCtx.beginPath();
          targetCtx.arc(
            (column * primeRatio) + (primeRatio * (PIXEL_SHIFT + YELLOW_OFFSET_X)),
            (row * primeRatio) + (primeRatio * (PIXEL_SHIFT + YELLOW_OFFSET_Y)),
            ((y * YELLOW_DOT_MULTIPLIER) * PIXEL_RADIUS), // radius, scaled to value
            0,
            PI2,
          );
          targetCtx.fillStyle = `rgba(${YELLOW_VALUE}, ${CMY_DOT_OPACITY})`;
          targetCtx.fill();
          targetCtx.closePath();
        }

        else if (mode === 'rgb') {

          // red
          targetCtx.beginPath();
          targetCtx.arc(
            (column * primeRatio) + (primeRatio * (PIXEL_SHIFT + RED_OFFSET_X)),
            (row * primeRatio) + (primeRatio * (PIXEL_SHIFT + RED_OFFSET_Y)),
            (((r/256) * RED_DOT_MULTIPLIER) * PIXEL_RADIUS), // radius, scaled to value
            0,
            PI2,
          );
          targetCtx.fillStyle = `rgba(${RED_VALUE}, ${RGB_DOT_OPACITY})`;
          targetCtx.fill();
          targetCtx.closePath();

          // green
          targetCtx.beginPath();
          targetCtx.arc(
            (column * primeRatio) + (primeRatio * (PIXEL_SHIFT + GREEN_OFFSET_X)),
            (row * primeRatio) + (primeRatio * (PIXEL_SHIFT + GREEN_OFFSET_Y)),
            (((g/256) * GREEN_DOT_MULTIPLIER) * PIXEL_RADIUS), // radius, scaled to value
            0,
            PI2,
          );
          targetCtx.fillStyle = `rgba(${GREEN_VALUE}, ${RGB_DOT_OPACITY})`;
          targetCtx.fill();
          targetCtx.closePath();

          // blue
          targetCtx.beginPath();
          targetCtx.arc(
            (column * primeRatio) + (primeRatio * (PIXEL_SHIFT + BLUE_OFFSET_X)),
            (row * primeRatio) + (primeRatio * (PIXEL_SHIFT + BLUE_OFFSET_Y)),
            (((b/256) * BLUE_DOT_MULTIPLIER) * PIXEL_RADIUS), // radius, scaled to value
            0,
            PI2,
          );
          targetCtx.fillStyle = `rgba(${BLUE_VALUE}, ${RGB_DOT_OPACITY})`;
          targetCtx.fill();
          targetCtx.closePath();
        }

      }

    }

    setVideoFrameRate(Math.round((performance.now() - t0) * 10) / 10);
  }

  const getPixelValue = (x, y, imgData, canvasWidth) => {
    const cursor = y * (canvasWidth * BYTES_PER_PIXEL) + x * BYTES_PER_PIXEL;
    return {
      r: imgData.data[cursor],
      g: imgData.data[cursor+1],
      b: imgData.data[cursor+2],
      a: imgData.data[cursor+3],
    };
  }

  const toGreyscale = (pixel) => {
    // return (0.333 * pixel.r + 0.333 * pixel.g + 0.333 * pixel.b);
    // return (0.499 * pixel.r + 0.587 * pixel.g + 0.114 * pixel.b);
    return (0.299 * pixel.r + 0.587 * pixel.g + 0.114 * pixel.b);
  }

  const getTargetPosition = (x, y) => {
    const w = targetWidth;
    const h = targetHeight;
    return [targetWidth * x, targetHeight * y];
  }

  const getSourceWidth = () => {
    return Math.floor(targetWidth / (dotSize / 2));
  }

  const getSourceHeight = () => {
    return Math.floor(targetHeight / (dotSize / 2));
  }

  const sourceCanvasWidth = getSourceWidth();
  const sourceCanvasHeight = getSourceHeight();

  return (
    <div className={styles.halftone}
      style={{
        width: `${targetWidth}`,
        height: `${targetHeight}`,
      }}
    >
      <canvas
        className={styles.targetCanvas}
        width={`${targetWidth}px`}
        height={`${targetHeight}px`}
        ref={targetCanvasRef}
        style={{
          backgroundColor: CANVAS_BG_COLOR, 
        }}
      />

      <canvas
        className={styles.sourceCanvas}
        width={sourceCanvasWidth}
        height={sourceCanvasHeight}
        ref={sourceCanvasRef}
      />

      {/* @TODO: fix this mess below */}
      {/* all this hackery below with the key ensures the video re-renders, and thus re-fires the required `play` event */}
      <video
        // key={`${source}_${fps}_${dotSize}_${targetWidth}_${targetHeight}`}
        key={`${source}_${fps}_${mode}_${bwDotColor}_${backgroundColor}_${targetWidth}_${targetHeight}`}
        className={styles.sourceVideo}
        width={`${sourceCanvasWidth}px`}
        height={`${sourceCanvasHeight}px`}
        ref={sourceVideoRef}
        autoPlay
        muted
        playsInline
        loop
        preload='metadata'
        poster='/img/waves-poster.jpg'
        type='video/mp4'
        src={`${source}`}
        style={{
          backgroundColor: CANVAS_BG_COLOR,
          objectFit: 'cover',
        }}
      />
    </div>
  );
};

export default Halftone;