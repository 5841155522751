import React from 'react';
import MarkdownIt from 'markdown-it';
import RecognitionItem from '@components/recognition-item';
import ResponsiveImage from '@components/responsive-image';
import ResponsiveVideo from '@components/responsive-video';
import * as styles from './styles.css.js';

const ProjectItem = ({item}) => {

  // console.log(item);

  const getAssociationFragment = (item) => {
    // @TODO: return just the first item for now, worry about multiples later
    if (item.association?.length) {
      return (
        <span className={styles.association}> <a href={ item.association[0].link_url } target="_blank">{ item.association[0].name }</a></span>
      );
    }
    else {
      return null;
    }
  }

  return (
    <div className={styles.item} id={item.slug}>

      { item.media ?
        <>

          { item.link_url ?
            <a href={ item.link_url } target="_blank">

              { item.media.type === 'image' ?
                <ResponsiveImage
                  image={item.media}
                />
              : null }

              { item.media.type === 'video' ?
                <ResponsiveVideo
                  video={item.media}
                />
              : null }

            </a>
          :
            <>
              { item.media.type === 'image' ?
                <ResponsiveImage
                  image={item.media}
                />
              : null }

              { item.media.type === 'video' ?
                <ResponsiveVideo
                  video={item.media}
                />
              : null }
            </>
          }

        </>
      : null }

      <div className={styles.details}>
        <div className={styles.detailsTop[item.description ? 'bordered' : 'unbordered']}>
          <h2>
            <span className={styles.title_bp_xs}>
              { item.link_url ?
                <a href={ item.link_url } target="_blank">
                  <span dangerouslySetInnerHTML={{ __html: item.title_bp_xs }} />
                  <svg className={styles.projectTitleLinkSvg} xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" viewBox="0 0 30 30">
                    <path d="M 25.980469 2.9902344 A 1.0001 1.0001 0 0 0 25.869141 3 L 20 3 A 1.0001 1.0001 0 1 0 20 5 L 23.585938 5 L 13.292969 15.292969 A 1.0001 1.0001 0 1 0 14.707031 16.707031 L 25 6.4140625 L 25 10 A 1.0001 1.0001 0 1 0 27 10 L 27 4.1269531 A 1.0001 1.0001 0 0 0 25.980469 2.9902344 z M 6 7 C 4.9069372 7 4 7.9069372 4 9 L 4 24 C 4 25.093063 4.9069372 26 6 26 L 21 26 C 22.093063 26 23 25.093063 23 24 L 23 14 L 23 11.421875 L 21 13.421875 L 21 16 L 21 24 L 6 24 L 6 9 L 14 9 L 16 9 L 16.578125 9 L 18.578125 7 L 16 7 L 14 7 L 6 7 z"></path>
                  </svg>
                </a>
              :
                <span dangerouslySetInnerHTML={{ __html: item.title_bp_xs }} />
              }
            </span>
            <span className={styles.title_bp_sm}>
              { item.link_url ?
                <a href={ item.link_url } target="_blank">
                  <span dangerouslySetInnerHTML={{ __html: item.title_bp_sm }} />
                  <svg className={styles.projectTitleLinkSvg} xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" viewBox="0 0 30 30">
                    <path d="M 25.980469 2.9902344 A 1.0001 1.0001 0 0 0 25.869141 3 L 20 3 A 1.0001 1.0001 0 1 0 20 5 L 23.585938 5 L 13.292969 15.292969 A 1.0001 1.0001 0 1 0 14.707031 16.707031 L 25 6.4140625 L 25 10 A 1.0001 1.0001 0 1 0 27 10 L 27 4.1269531 A 1.0001 1.0001 0 0 0 25.980469 2.9902344 z M 6 7 C 4.9069372 7 4 7.9069372 4 9 L 4 24 C 4 25.093063 4.9069372 26 6 26 L 21 26 C 22.093063 26 23 25.093063 23 24 L 23 14 L 23 11.421875 L 21 13.421875 L 21 16 L 21 24 L 6 24 L 6 9 L 14 9 L 16 9 L 16.578125 9 L 18.578125 7 L 16 7 L 14 7 L 6 7 z"></path>
                  </svg>
                </a>
              :
                <span dangerouslySetInnerHTML={{ __html: item.title_bp_sm }} />
              }
            </span>
            <span className={styles.title_bp_md}>
              { item.link_url ?
                <a href={ item.link_url } target="_blank">
                  <span dangerouslySetInnerHTML={{ __html: item.title_bp_md }} />
                  <svg className={styles.projectTitleLinkSvg} xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" viewBox="0 0 30 30">
                    <path d="M 25.980469 2.9902344 A 1.0001 1.0001 0 0 0 25.869141 3 L 20 3 A 1.0001 1.0001 0 1 0 20 5 L 23.585938 5 L 13.292969 15.292969 A 1.0001 1.0001 0 1 0 14.707031 16.707031 L 25 6.4140625 L 25 10 A 1.0001 1.0001 0 1 0 27 10 L 27 4.1269531 A 1.0001 1.0001 0 0 0 25.980469 2.9902344 z M 6 7 C 4.9069372 7 4 7.9069372 4 9 L 4 24 C 4 25.093063 4.9069372 26 6 26 L 21 26 C 22.093063 26 23 25.093063 23 24 L 23 14 L 23 11.421875 L 21 13.421875 L 21 16 L 21 24 L 6 24 L 6 9 L 14 9 L 16 9 L 16.578125 9 L 18.578125 7 L 16 7 L 14 7 L 6 7 z"></path>
                  </svg>
                </a>
              :
                <span dangerouslySetInnerHTML={{ __html: item.title_bp_md }} />
              }
            </span>
          </h2>
          <p className={styles.sub}>
            {( getAssociationFragment(item) )} <span>({ item.year })</span>
            {/*<span className={styles.subRole}>{ item.role }</span>*/}
          </p>
        </div>
        
        { item.description || (item.recognition && item.recognition.length > 0) ?
          <div className={styles.detailsBottom}>
            
            { item.description ?
              <>
                <div className={styles.description}>
                  <span dangerouslySetInnerHTML={{ __html: item.description }} />
                  { item.link_url ?
                    <p>
                      <a className={styles.projectLink} href={item.link_url} target="_blank">
                        <span>See the project</span>
                        <svg className={styles.projectDescriptionLinkSvg} xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" viewBox="0 0 30 30">
                          <path d="M 25.980469 2.9902344 A 1.0001 1.0001 0 0 0 25.869141 3 L 20 3 A 1.0001 1.0001 0 1 0 20 5 L 23.585938 5 L 13.292969 15.292969 A 1.0001 1.0001 0 1 0 14.707031 16.707031 L 25 6.4140625 L 25 10 A 1.0001 1.0001 0 1 0 27 10 L 27 4.1269531 A 1.0001 1.0001 0 0 0 25.980469 2.9902344 z M 6 7 C 4.9069372 7 4 7.9069372 4 9 L 4 24 C 4 25.093063 4.9069372 26 6 26 L 21 26 C 22.093063 26 23 25.093063 23 24 L 23 14 L 23 11.421875 L 21 13.421875 L 21 16 L 21 24 L 6 24 L 6 9 L 14 9 L 16 9 L 16.578125 9 L 18.578125 7 L 16 7 L 14 7 L 6 7 z"></path>
                        </svg>
                      </a>
                    </p>
                  : null }
                </div>
              </>
            : null }
          
            {( item.recognition && item.recognition.length > 0 ?
              <div className={styles.recognition}>
                <ul>
                  { item.recognition.map((recognition_item, index) => {
                    return (
                      <RecognitionItem
                        item={recognition_item}
                        key={index}
                      />
                    )
                  })}
                </ul>
              
              </div>
            : null )}
          
          </div>
        : null }
        
      </div>
    </div>
  );
}

export default ProjectItem;
