import React, { useEffect, useState } from 'react';
import * as styles from './styles.css.js';

const Socials = ({socials, theme = 'light', style = '', display = 'block'}) => {

  const [emailText, setEmailText] = useState('');
  const [emailLink, setEmailLink] = useState('');

  useEffect(() => {
    if (emailText === '' && emailLink === '') {
      const email = socials.find((social) => {
        return social.id === 'email';
      });

      setEmailText(atob(email.text));
      setEmailLink(atob(email.link));
    }
  }, []);

  return (
    <ul className={`${styles.socialsList} ${style}`}>
      { socials.map(({id, label, text, link}, idx) => {
        return (
          <li key={idx} className={styles.socialsListItem({
              display: display,
            })}>
            <div
              className={styles.socialsListItemLink({
                theme: theme,
              })
            }>
              <div className={styles.socialsListItemLabel({theme: theme})}>
                {label}
              </div>
              <a className={styles.socialsListItemText({theme: theme})} href={id === 'email' ? emailLink : link} target="_blank">
                {id === 'email' ? emailText : text}
              </a>
            </div>
          </li>
        );
      })}
    </ul>
  );
}

export default Socials;
