import React, { useEffect } from 'react';
import { debounce } from 'lodash';
import { breakpoints as breakpoints } from '@src/breakpoints';
import useStore from '@store';
// `main` below are the global styles site pages (not tied to any one component)
import * as main from '@styles/main.css.js'; // eslint-disable-line
import * as styles from './styles.css.js';

const Page = (props) => {
  const {
    setViewport,
  } = useStore.getState().actions;

  useEffect(() => {
    function onResize() {
      const windowWidth =  window.innerWidth;
      const windowHeight =  window.innerHeight;

      const vh = windowHeight * 0.01;
      const target = windowWidth > breakpoints.md ? 'desktop' : 'mobile';

      setViewport(target);

      if (!document.documentElement.style.getPropertyValue('--vh').length || target !== 'mobile') {
        document.documentElement.style.setProperty('--vh', `${vh}px`);
      } 
    }

    const fn = debounce(onResize, 16);
    window.addEventListener('resize', fn);
    onResize();

    return () => window.removeEventListener('resize', fn)
  }, []);

  return (
    <div className={styles.page}>
      {props.children}
    </div>
  );
}

export default Page;
